import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default ({ data }) => {
  return (<Layout>
    <SEO title="Contact" keywords={[]}/>

    <div className='container'>
      <div className="grid">
        <div className="grid__column">
          <h1 className='title title--xxxl color-primary'>
            Contact Infinity Structures
          </h1>
        </div>
      </div>
      <div className='grid'>
        <div className='grid__column grid__column--12 grid__column--6--sm'>
          <Img fluid={data.iContact.childImageSharp.fluid} className=''/>
        </div>

        <div className='grid__column grid__column--12 grid__column--6--sm'>
          <p className='type--lg type-bold'>
            Infinity Structures, Inc.<br/>
            6250 Shiloh Road<br/>
            Suite 210<br/>
            Alpharetta, GA 30005<br/>
            (678) 513-4080
          </p>
        </div>
      </div>
    </div>
  </Layout>);
}

// TODO: childImageSharp should become a fragment, should convert all to jpg?
export const pageQuery = graphql`
    query {
        iContact: file(relativePath: { eq: "contact/officebuilding.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 455) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

